import {
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  useIonRouter,
} from "@ionic/react";
import {
  add,
  personAddOutline,
  cashOutline,
  basketOutline,
} from "ionicons/icons";
import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentGroup } from "../redux/reducer/app-slice";

const Home = () => {
  const group = useSelector((store) => selectCurrentGroup(store));
  const router = useIonRouter();

  return (
    <IonContent color="light">
      {group?.expenses?.length > 0 ? (
        <IonList>
          {group.expenses.map((expense) => (
            <IonItem key={expense.id}>
              <IonLabel>{expense.name}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      ) : (
        <IonLabel color={"dark"}>noch keine Ausgaben</IonLabel>
      )}
      <IonFab
        slot="fixed"
        vertical="bottom"
        horizontal="end"
        className="ion-padding"
      >
        <IonFabButton>
          <IonIcon icon={add} />
        </IonFabButton>

        <IonFabList side="top">
          <IonFabButton>
            <IonIcon icon={personAddOutline} />
          </IonFabButton>
          <IonFabButton>
            <IonIcon icon={cashOutline} />
          </IonFabButton>
          <IonFabButton onClick={() => router.push("/create-expense")}>
            <IonIcon icon={basketOutline} />
          </IonFabButton>
        </IonFabList>
      </IonFab>
    </IonContent>
  );
};

export default Home;
