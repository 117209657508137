import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonRow,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import CustomInput from "../features/custom-input";

const JoinGroup = () => {
  const [code, setCode] = useState("");

  const codeInput = useRef(null);

  useIonViewDidEnter(() => {
    codeInput.current?.setFocus();
  });

  const handleCodeInput = (event) => {
    console.log(event.target.value);
    setCode(event.target.value);
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton>BEITRETEN</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="primary" className="ion-padding">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="8">
              <CustomInput>
                <IonInput
                  ref={codeInput}
                  autofocus={true}
                  type="text"
                  className="custom uppercase"
                  maxlength={6}
                  placeholder="... ..."
                  helperText="Gib einen Code ein"
                  onIonInput={handleCodeInput}
                ></IonInput>
              </CustomInput>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default JoinGroup;
