import { setupIonicReact, IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./scenes/home";
import { Route } from "react-router-dom";
import AppLayout from "./scenes/app-layout";
import JoinGroup from "./scenes/join-group";
import CreateGroup from "./scenes/create-group";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  createSessionId,
  selectCurrentGroupName,
} from "./redux/reducer/app-slice";
import AddParticipants from "./scenes/add-participants";
import CreateExpense from "./scenes/create-expense";

import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./theme/variables.css";

function App() {
  const dispatch = useDispatch();
  const currentGroupName = useSelector((state) =>
    selectCurrentGroupName(state)
  );

  useEffect(() => {
    dispatch(createSessionId());
  }, []);

  setupIonicReact();
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route
            path="/join-group"
            exact={true}
            component={() => (
              <AppLayout header={false}>
                <JoinGroup />
              </AppLayout>
            )}
          />
          <Route
            path="/create-group"
            exact={true}
            component={() => (
              <AppLayout header={false}>
                <CreateGroup />
              </AppLayout>
            )}
          />
          <Route
            path="/add-participants"
            exact={true}
            component={() => (
              <AppLayout header={false}>
                <AddParticipants />
              </AppLayout>
            )}
          />
          <Route
            path="/create-expense"
            exact={true}
            component={() => (
              <AppLayout header={false}>
                <CreateExpense />
              </AppLayout>
            )}
          />
          <Route
            path="/"
            exact={true}
            component={() => (
              <AppLayout title={currentGroupName}>
                <Home />
              </AppLayout>
            )}
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
