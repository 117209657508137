import React, { useState } from "react";
import {
  IonFooter,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
} from "@ionic/react";
import AddGroup from "../features/add-group";
import { useDispatch, useSelector } from "react-redux";
import { getGroupStatus, selectGroups } from "../redux/reducer/app-slice";
import { menuController } from "@ionic/core/components";

const AppLayout = ({
  header = true,
  footer = true,
  title = "Home",
  children,
}) => {
  const groups = useSelector((state) => selectGroups(state));
  const dispatch = useDispatch();

  const handleGroupSwitch = (groupId) => {
    dispatch(getGroupStatus(groupId));
    menuController.close();
  };

  return (
    <>
      <IonMenu contentId="main-content">
        <IonHeader className="ion-no-border">
          <IonToolbar color="primary">
            <IonTitle>Gruppen</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" color="primary">
          <IonList style={{ background: "transparent" }}>
            {groups.map((group) => {
              return (
                <IonItem
                  color={"primary"}
                  key={group.id}
                  button
                  onClick={() => handleGroupSwitch(group.id)}
                >
                  {group.name}
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
        <AddGroup />
      </IonMenu>
      <IonPage id="main-content">
        {header && (
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonMenuButton autoHide={false}></IonMenuButton>
              </IonButtons>
              <IonTitle>{title}</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent>{children}</IonContent>
        {/*  {footer && (
          <IonFooter>
            <IonToolbar>
              <IonTitle>My App Footer</IonTitle>
            </IonToolbar>
          </IonFooter>
        )} */}
      </IonPage>
    </>
  );
};

export default AppLayout;
