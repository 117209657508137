import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRow,
  IonSpinner,
  IonToolbar,
  useIonActionSheet,
  useIonRouter,
  useIonToast,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createExpense, selectCurrentGroup } from "../redux/reducer/app-slice";

const CreateExpense = () => {
  const [title, setTitle] = useState("");
  const [from, setFrom] = useState(null);
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receivers, setReceivers] = useState([]);

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const router = useIonRouter();

  const [presentToast] = useIonToast();
  const [present] = useIonActionSheet();

  const currentGroup = useSelector((state) => selectCurrentGroup(state));

  const dispatch = useDispatch();

  const amountInput = useRef(null);
  const titleInput = useRef(null);

  useIonViewDidEnter(() => {
    titleInput.current?.setFocus();
  });

  const handleTitleInput = (event) => {
    setTitle(event.target.value);
  };

  const handleSubmit = (event) => {
    if (title === "") {
      showToast("Gib einen Titel ein.");
      return;
    }

    if (!amount) {
      showToast("Gib einen Betrag ein.");
      return;
    }

    if (!from) {
      showToast("Wähle ausein wer bezahlt hat.");
      return;
    }

    setIsSubmitModalOpen(true);

    let participantReceivers = [];
    if (receivers.length === 0) {
      participantReceivers = currentGroup.participants.map((participant) => {
        return {
          ...participant,
          share: formatMoneyInput(amount / currentGroup.participants.length),
        };
      });
    }

    console.log(receivers);
    dispatch(
      createExpense(
        {
          title,
          amount,
          from,
          receivers: receivers.length === 0 ? participantReceivers : receivers,
        },
        () => {
          router.push("/");
          setIsSubmitModalOpen(false);
        }
      )
    );
    event.preventDefault();
  };

  const selectFrom = () => {
    present({
      buttons: currentGroup.participants.map((participant) => {
        return {
          text: participant.name,
          data: {
            action: participant.id,
          },
          handler: () => {
            setFrom(participant);
          },
        };
      }),
    });
  };

  const formatMoneyInput = (value) => {
    if (isNaN(value) || value < 0) {
      return;
    }
    const number = parseFloat(value);
    const numeric = number.toFixed(2);
    return numeric;
  };

  const removeFromReceiverList = (participantId) => {
    const newReceiverCount = receivers.length - 1;
    setReceivers(
      receivers
        .filter((receiver) => receiver.id !== participantId)
        .map((receiver) => ({
          ...receiver,
          share: formatMoneyInput(amount / newReceiverCount),
        }))
    );
  };

  const addToReceiverList = (participant) => {
    const newReceiverCount = receivers.length + 1;
    console.log(newReceiverCount);

    console.log(participant);
    setReceivers([
      ...receivers.map((receiver) => ({
        ...receiver,
        share: formatMoneyInput(amount / newReceiverCount),
      })),
      {
        ...participant,
        share: formatMoneyInput(amount / newReceiverCount),
      },
    ]);
  };

  const handleShareOnBlur = (newAmount, receiver) => {
    const newReceiverCount = receivers.length - 1;
    setReceivers(
      receivers.map((oldReceiver) => {
        return {
          ...oldReceiver,
          share:
            receiver.id === oldReceiver.id
              ? newAmount
              : formatMoneyInput((amount - newAmount) / newReceiverCount),
        };
      })
    );
  };

  const getFormattedAmount = (numeric) => {
    if (isNaN(numeric)) {
      console.log(numeric);
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(0);
    }
    const formattedAmount = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(numeric);

    return formattedAmount;
  };

  const getPersonCount = () => {
    const personCount = receivers.length || currentGroup.participants.length;
    return `${personCount} Person${personCount > 1 && "en"}`;
  };

  const showToast = (text) => {
    presentToast({
      message: text,
      duration: 1500,
      position: "bottom",
    });
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={handleSubmit}>SICHERN</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar color="primary">
          <IonInput
            autofocus={true}
            ref={titleInput}
            type="text"
            placeholder="Titel"
            className="ion-padding"
            onIonInput={(event) => handleTitleInput(event)}
            color={"light"}
          ></IonInput>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {!loading ? (
          <form onSubmit={handleSubmit}>
            <IonList inset={true}>
              <IonItem onClick={() => amountInput.current?.setFocus()} button>
                <IonLabel slot="start">Beitrag</IonLabel>
                <IonInput
                  ref={amountInput}
                  type="number"
                  aria-label="Beitrag"
                  placeholder={"0,00"}
                  slot="end"
                  value={amount}
                  inputMode="numeric"
                  className="ion-text-right"
                  onIonBlur={(e) => {
                    const value = formatMoneyInput(e.target.value);
                    setAmount(value);
                  }}
                >
                  <IonLabel slot="end">€</IonLabel>
                </IonInput>
              </IonItem>
            </IonList>
            <IonList inset={true}>
              <IonItem onClick={() => selectFrom()} button>
                <IonLabel>Von</IonLabel>
                <IonLabel slot="end">{from?.name}</IonLabel>
              </IonItem>
              <IonItem id="open-modal" button>
                <IonLabel>Für</IonLabel>
                <IonLabel slot="end">{getPersonCount()}</IonLabel>
              </IonItem>
            </IonList>
          </form>
        ) : (
          <IonGrid>
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="1">
                <IonSpinner />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
      <IonModal id="example-modal" trigger="open-modal">
        <IonGrid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {receivers.length === 0 ? (
            <IonRow>
              <IonCol size="12" className="ion-padding ion-text-center">
                <IonLabel color={"medium"}>Für jeden</IonLabel>
              </IonCol>
              <IonCol size="12" className="ion-padding  ion-text-center">
                <IonLabel>
                  {getFormattedAmount(
                    amount / currentGroup.participants.length
                  )}
                  <IonLabel slot="end">€</IonLabel>
                </IonLabel>
              </IonCol>
            </IonRow>
          ) : (
            <IonRow style={{ width: "100%" }}>
              <IonCol size="12">
                <IonList>
                  <IonListHeader>Anteilig</IonListHeader>
                  {receivers.map((receiver) => (
                    <IonItem key={receiver.id}>
                      <IonLabel slot="start">{receiver.name}</IonLabel>
                      <IonInput
                        type="number"
                        aria-label={receiver.name}
                        slot="end"
                        inputMode="numeric"
                        className="ion-text-right"
                        value={receiver.share}
                        onIonChange={(event) =>
                          handleShareOnBlur(event.target.value, receiver)
                        }
                      >
                        <IonLabel slot="end">€</IonLabel>
                      </IonInput>
                    </IonItem>
                  ))}
                </IonList>
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol>
              {currentGroup.participants.map((participant) => {
                const isReceiver = receivers.some(
                  (receiver) => receiver.id === participant.id
                );
                return (
                  <IonLabel key={participant.id}>
                    <IonChip
                      color={isReceiver ? "primary" : undefined}
                      outline={!isReceiver}
                      onClick={() =>
                        isReceiver
                          ? removeFromReceiverList(participant.id)
                          : addToReceiverList(participant)
                      }
                    >
                      <IonAvatar>
                        <img
                          alt="Silhouette of a person's head"
                          src="https://ionicframework.com/docs/img/demos/avatar.svg"
                        />
                      </IonAvatar>
                      <IonLabel>{participant.name}</IonLabel>
                    </IonChip>
                  </IonLabel>
                );
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
      <IonModal isOpen={isSubmitModalOpen}>
        <IonContent color={"primary"}>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IonSpinner color={"light"} />
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default CreateExpense;
