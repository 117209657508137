import {
  IonActionSheet,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  useIonRouter,
} from "@ionic/react";
import { add, addCircleOutline, enterOutline } from "ionicons/icons";
import { menuController } from "@ionic/core/components";

import React from "react";

const AddGroup = () => {
  const router = useIonRouter();

  const navigateTo = (target) => {
    router.push(target);
  };

  return (
    <IonContent>
      <IonFab slot="fixed" vertical="top" horizontal="end" edge={true}>
        <IonFabButton id="open-add-group-as" color="light">
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonActionSheet
        trigger="open-add-group-as"
        buttons={[
          {
            text: "Neue Gruppe",
            icon: addCircleOutline,
            data: {
              action: "create",
            },
            handler: () => {
              console.log("created");
              menuController.close();
              navigateTo("/create-group");
            },
          },
          {
            text: "Gruppe beitreten",
            routerLink: "/join-group",
            icon: enterOutline,
            data: {
              action: "join",
            },
            handler: () => {
              console.log("joined");
              menuController.close();
              navigateTo("/join-group");
            },
          },
        ]}
      ></IonActionSheet>
    </IonContent>
  );
};

export default AddGroup;
