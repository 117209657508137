import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonRow,
  IonSpinner,
  IonToast,
  IonToggle,
  IonToolbar,
  useIonRouter,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { createGroup } from "../redux/reducer/app-slice";

const CreateGroup = () => {
  const [groupName, setGroupName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useIonRouter();

  const dispatch = useDispatch();

  const nameInput = useRef(null);

  useIonViewDidEnter(() => {
    nameInput.current?.setFocus();
  });

  const handleNameInput = (event) => {
    setGroupName(event.target.value);
  };

  const handleSubmit = () => {
    if (groupName === "") {
      setIsOpen(true);
    } else {
      setLoading(true);
      dispatch(
        createGroup(groupName, () => {
          setLoading(false);
          router.push("/add-participants");
        })
      );
    }
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => handleSubmit()}>WEITER</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="primary" className="ion-padding">
        {!loading ? (
          <form onSubmit={() => handleSubmit()}>
            <IonCard>
              <IonGrid>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12">
                    <IonInput
                      ref={nameInput}
                      autofocus={true}
                      type="text"
                      fill="solid"
                      placeholder="Name"
                      onIonInput={handleNameInput}
                    ></IonInput>
                    <IonList>
                      <IonItem>
                        <IonToggle disabled={true}>
                          Nur auf diesem Gerät speichern
                        </IonToggle>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </form>
        ) : (
          <IonGrid>
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="1">
                <IonSpinner color={"light"} />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
      <IonToast
        isOpen={isOpen}
        message="Gib einen Namen ein."
        onDidDismiss={() => setIsOpen(false)}
        duration={2000}
      ></IonToast>
    </>
  );
};

export default CreateGroup;
